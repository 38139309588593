<template>
  <VueDatePicker
    v-model="value"
    class="base-date-input"
    :locale="locale === 'pl' ? 'pl-PL' : 'en-US'"
    :format-locale="locale === 'pl' ? pl : undefined"
    :format="
      (date: Date | null) => (date ? format(date, 'dd.MM.yyyy HH:mm') : '')
    "
    :select-text="t('form.select')"
    :cancel-text="t('form.cancel')"
  />
</template>

<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker'
import { format } from 'date-fns'
import { pl } from 'date-fns/locale'
import { useField } from 'vee-validate'

import '@vuepic/vue-datepicker/dist/main.css'

const { t } = useI18n()
const props = defineProps<{
  name: string
  rules?: string
}>()

const { value } = useField<Date>(() => props.name, props.rules)

const { locale } = useI18n()
</script>

<style lang="scss" scoped>
.base-date-input {
  --dp-cell-border-radius: 50%;
  --dp-font-family: $font;
  position: relative;
}

:deep(.dp__input) {
  padding: 0;
  font: inherit;
  color: inherit;
  cursor: inherit;
  background: transparent;
  border: 0;
}

:deep(.dp__input_icons) {
  display: none;
}

:deep(.dp__theme_light) {
  --dp-background-color: #{$beige};
  --dp-text-color: #{$dark-brown};
  --dp-hover-color: #{$beige};
  --dp-hover-text-color: #{$dark-brown};
  --dp-hover-icon-color: #{$dark-brown};
  --dp-primary-color: #{$dark-brown};
  --dp-primary-disabled-color: #{$light-brown};
  --dp-primary-text-color: #{$beige};
  --dp-secondary-color: #{$dark-brown-30};
  --dp-border-color: transparent;
  --dp-menu-border-color: transparent;
  --dp-border-color-hover: transparent;
  --dp-border-color-focus: transparent;
  --dp-disabled-color: #{$beige};
  --dp-scroll-bar-background: #{$beige};
  --dp-scroll-bar-color: #{$dark-brown};
  --dp-icon-color: #{$dark-brown};
  --dp-tooltip-color: #{$beige};
  --dp-disabled-color-text: #{$dark-brown};
  --dp-highlight-color: #{$dark-brown};
}
</style>
